import contact from '../assets/images/contact.svg';
import React from 'react'
import Navbar from '../component/navbar'
import ContactData from '../component/contact-data';
import Footer from '../component/footer';

export default function ContactUs() {
    return (
        <>
            <Navbar />
            <section className="py-28 w-full table relative bg-[url('../../assets/images/bg/bg-5.jpg')] bg-center bg-no-repeat" id="home">
                <div className="absolute inset-0 bg-slate-950/60"></div>

                <div className="container">
                    <div className="grid grid-cols-1 pb-8 mt-10 text-center">
                        <h3 className="mt-10 text-3xl font-medium leading-normal text-white">Contact Us</h3>
                    </div>
                </div>
            </section>
            <section className="relative py-16 lg:py-24">
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-7 md:col-span-6">
                            <img src={contact} alt="" />
                        </div>

                        <div className="lg:col-span-5 md:col-span-6">
                            <div className="lg:ms-5">
                                <div className="p-6 bg-white rounded-md shadow dark:bg-slate-900 dark:shadow-gray-700">
                                    <h3 className="mb-6 text-2xl font-medium leading-normal">Get in touch !</h3>

                                    <form method="post" name="myForm" id="myForm" onsubmit="return validateForm()">
                                        <p className="mb-0" id="error-msg"></p>
                                        <div id="simple-msg"></div>
                                        <div className="grid lg:grid-cols-12 lg:gap-6">
                                            <div className="mb-5 lg:col-span-6">
                                                <label htmlFor="name" className="font-medium form-label">Your Name:</label>
                                                <input name="name" id="name" type="text" className="w-full h-10 px-3 py-2 mt-2 bg-transparent border rounded outline-none form-input border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0" placeholder="Name :" />
                                            </div>

                                            <div className="mb-5 lg:col-span-6">
                                                <label htmlFor="email" className="font-medium form-label">Your Email:</label>
                                                <input name="email" id="email" type="email" className="w-full h-10 px-3 py-2 mt-2 bg-transparent border rounded outline-none form-input border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0" placeholder="Email :" />
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-1">
                                            <div className="mb-5">
                                                <label htmlFor="subject" className="font-medium form-label">Your Question:</label>
                                                <input name="subject" id="subject" className="w-full h-10 px-3 py-2 mt-2 bg-transparent border rounded outline-none form-input border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0" placeholder="Subject :" />
                                            </div>

                                            <div className="mb-5">
                                                <label htmlFor="comments" className="font-medium form-label">Your Comment:</label>
                                                <textarea name="comments" id="comments" className="w-full px-3 py-2 mt-2 bg-transparent border rounded outline-none form-input border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 textarea h-28" placeholder="Message :"></textarea>
                                            </div>
                                        </div>
                                        <button type="submit" id="submit" name="send" className="inline-block px-5 py-2 text-base font-normal tracking-wide text-center text-white align-middle transition duration-500 ease-in-out border rounded-md bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700">Send Message</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ContactData />
            </section>
            
            <Footer />
        </>
    )
}