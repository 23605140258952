import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; 

export default function Signup() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    termsAccepted: false,
  });
  const [emailError, setEmailError] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setEmailError("");
    setPasswordMatchError(false);

    if (!formData.termsAccepted) {
      toast.error("Please accept the terms and conditions.");
      setLoading(false);
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setPasswordMatchError(true);
      setLoading(false);
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      setEmailError("Please enter a valid email address.");
      setLoading(false);
      return;
    }
    
    try {
      console.log("Received registration request:", formData);

      // Call external registration API
      console.log("Calling external registration API...");
      const response = await axios.post(
        "https://thanayama.com/skyheaven-0.1/user/save",
        {
          name: formData.name,
          username: formData.email,
          password: formData.password,
        }
      );

      const user = response.data;
      console.log("User registered successfully:", user);

      toast.success("Registration successful");
      // Redirect to login page after successful registration
      window.location.href = 'http://thanayama.com:81/'; // Assuming '/' is your login page route
    } catch (error) {
      console.error(
        "Error during registration:",
        error.response ? error.response.data : error.message
      );
      toast.error("Registration failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="position-relative bg-[url('../../assets/images/bg/bg-4.jpg')] bg-center bg-no-repeat">
      <div className="absolute inset-0 bg-slate-950/50"></div>
      <div className="relative container-fluid">
        <div className="grid grid-cols-1">
          <div className="lg:col-span-4">
            <div className="flex flex-col min-h-screen px-3 py-6 md:px-12">
              <div className="my-auto text-center">
                <div className="w-full max-w-sm px-6 py-6 m-auto bg-white rounded-md shadow-lg dark:bg-slate-900 shadow-slate-500 dark:shadow-gray-800">
                  <div className="grid grid-cols-1">
                    <h5 className="mb-6 text-xl font-medium dark:text-white">Signup</h5>
                    <form className="text-start" onSubmit={handleSubmit}>
                      <div className="grid grid-cols-1">
                        <div className="mb-3">
                          <label className="font-medium form-label" htmlFor="name">
                            Your Name:
                          </label>
                          <input
                            id="name"
                            type="text"
                            className="w-full h-10 px-3 py-2 mt-1 bg-transparent border rounded outline-none form-input border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0"
                            placeholder="Harry"
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                        </div>

                        <div className="mb-3">
                          <label className="font-medium form-label" htmlFor="email">
                            Email Address:
                          </label>
                          <input
                            id="email"
                            type="email"
                            className="w-full h-10 px-3 py-2 mt-1 bg-transparent border rounded outline-none form-input border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0"
                            placeholder="name@example.com"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                          {emailError && (
                            <p className="mt-2 text-sm text-violet-600">{emailError}</p>
                          )}
                        </div>

                        <div className="mb-3">
                          <label className="font-medium form-label" htmlFor="password">
                            Password:
                          </label>
                          <input
                            id="password"
                            type="password"
                            className="w-full h-10 px-3 py-2 mt-1 bg-transparent border rounded outline-none form-input border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0"
                            placeholder="Password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                          />
                        </div>

                        <div className="mb-3">
                          <label className="font-medium form-label" htmlFor="confirmPassword">
                            Confirm Password:
                          </label>
                          <input
                            id="confirmPassword"
                            type="password"
                            className={`w-full h-10 px-3 py-2 mt-1 bg-transparent border rounded outline-none form-input border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 ${
                              passwordMatchError ? "border-violet-600" : ""
                            }`}
                            placeholder="Confirm Password"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            required
                          />
                          {passwordMatchError && (
                            <p className="mt-2 text-sm text-violet-600">Passwords do not match!</p>
                          )}
                        </div>

                        <div className="mb-4">
                          <div className="flex items-center w-full mb-0">
                            <input
                              className="border-gray-200 rounded form-checkbox dark:border-gray-800 text-violet-600 focus:border-violet-300 focus:ring focus:ring-offset-0 focus:ring-violet-200 focus:ring-opacity-50 me-2"
                              type="checkbox"
                              id="termsAccepted"
                              checked={formData.termsAccepted}
                              onChange={handleChange}
                            />
                            <label className="form-check-label text-slate-400" htmlFor="termsAccepted">
                              I Accept Terms And Conditions
                            </label>
                          </div>
                        </div>

                        <div className="mb-3">
                          <button
                            type="submit"
                            className="inline-block w-full px-5 py-2 text-base font-normal tracking-wide text-center text-white align-middle transition duration-500 ease-in-out border rounded-md bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700"
                            disabled={loading}
                          >
                            {loading ? "Processing..." : "Register"}
                          </button>
                        </div>
                      </div>
                    </form>
                    <ToastContainer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}