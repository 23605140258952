import React from 'react'
import { Link } from "react-router-dom";

export default function GetInTouch() {
    return (
        <>
            <section className="relative py-16 md:py-24 bg-gray-50 dark:bg-slate-800" id="contact">
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 text-xl font-medium md:text-2xl">Get In Touch !</h3>
                        <p className="max-w-lg mx-auto text-slate-400 dark:text-slate-300">Your inquiries matter to us! Whether you have questions about our services, need assistance with your work, or want to learn more about our features, our dedicated support team is here to help. Don’t hesitate to reach out, and let us assist you in making your experience with us seamless and enjoyable!</p>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 mt-8 items-center gap-[30px]">
                        <div className="lg:col-span-8">
                            <div className="p-6 bg-white rounded-md shadow dark:bg-slate-900">
                                <form method="post" name="myForm" id="myForm" onsubmit="return validateForm()">
                                    <p className="mb-0" id="error-msg"></p>
                                    <div id="simple-msg"></div>
                                    <div className="grid lg:grid-cols-12 lg:gap-[30px]">
                                        <div className="mb-5 lg:col-span-6">

                                            <input name="name" id="name" type="text" className="w-full h-10 px-3 py-2 bg-transparent border rounded outline-none form-input border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0"
                                                placeholder="Name :" />
                                        </div>

                                        <div className="mb-5 lg:col-span-6">
                                            <input name="email" id="email" type="email" className="w-full h-10 px-3 py-2 bg-transparent border rounded outline-none form-input border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0"
                                                placeholder="Email :" />
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-1">
                                        <div className="mb-5">
                                            <input name="subject" id="subject" className="w-full h-10 px-3 py-2 bg-transparent border rounded outline-none form-input border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0"
                                                placeholder="Subject :" />
                                        </div>

                                        <div className="mb-5">
                                            <textarea name="comments" id="comments"
                                                className="w-full px-3 py-2 bg-transparent border rounded outline-none form-input border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 h-28"
                                                placeholder="Message :"></textarea>
                                        </div>
                                    </div>
                                    <button type="submit" id="submit" name="send"
                                        className="inline-block px-5 py-2 text-base font-normal tracking-wide text-center text-white align-middle transition duration-500 ease-in-out border rounded-md bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700">Send
                                        Message</button>
                                </form>
                            </div>
                        </div>

                        <div className="lg:col-span-4">
                            <div className="lg:ms-8">
                                <div className="flex">
                                    <div className="mx-auto text-center icons">
                                        <i className="block mb-0 text-2xl rounded uil uil-phone dark:text-white"></i>
                                    </div>

                                    <div className="flex-1 ms-6">
                                        <h5 className="mb-2 text-lg font-medium dark:text-white">Phone</h5>
                                        <Link to="tel:+152534-468-854" className="text-slate-400">(+94)70 24 93 211</Link>
                                    </div>
                                </div>

                                <div className="flex mt-4">
                                    <div className="mx-auto text-center icons">
                                        <i className="block mb-0 text-2xl rounded uil uil-envelope dark:text-white"></i>
                                    </div>

                                    <div className="flex-1 ms-6">
                                        <h5 className="mb-2 text-lg font-medium dark:text-white">Email</h5>
                                        <Link to="mailto:contact@example.com" className="text-slate-400">info@knowebsolutions.com​</Link>
                                    </div>
                                </div>

                                <div className="flex mt-4">
                                    <div className="mx-auto text-center icons">
                                        <i className="block mb-0 text-2xl rounded uil uil-map-marker dark:text-white"></i>
                                    </div>

                                    <div className="flex-1 ms-6">
                                        <h5 className="mb-2 text-lg font-medium dark:text-white">Location</h5>
                                        <p className="mb-2 text-slate-400">No. 422, 4th floor, Sanvik plaza, Wakwella Road, Galle</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
